input,
select {
  height: 50px !important;
  text-transform: uppercase !important;
}
textarea {
  text-transform: uppercase;
}

input.minusculo,
textarea.minusculo,
select.minusculo {
  text-transform: lowercase;
}

input.normal,
textarea.normal,
select.normal {
  text-transform: none !important;
}

input.compacto,
textarea.compacto,
select.compacto {
  height: 20px !important;
}

select.compacto {
  padding: 0px !important;
}

input:disabled,
select:disabled {
  background-color: #f5f5f5;
}

.modo-escuro input:disabled,
.modo-escuro select:disabled {
  background-color: #a4a4a4 !important;
  color: #000;
}

.inputInvisivel,
.inputInvisivel:focus {
  border: none !important;
  background: none !important;
  padding: 0px 0px 0px 5px;
  border-color: none;
  box-shadow: none;
  outline: 0 none;
}

input.dataTransparencia[type='date']::-webkit-datetime-edit {
  color: transparent !important;
}
input.dataTransparencia[type='date']:invalid::-webkit-datetime-edit {
  color: transparent !important;
}
input.dataTransparencia[type='date']:valid::-webkit-datetime-edit {
  color: #000 !important;
}
input.dataTransparencia[type='date']:focus::-webkit-datetime-edit {
  color: #000 !important;
}

input.dataTransparencia[type='date'] {
  color: transparent !important;
}
input.dataTransparencia[type='date']:invalid {
  color: transparent !important;
}
input.dataTransparencia[type='date']:valid {
  color: #000 !important;
}
input.dataTransparencia[type='date']:focus {
  color: #000 !important;
}

.inputProcurar::-webkit-file-upload-button {
  visibility: hidden;
}
.inputProcurar::before {
  content: 'Procurar';
  display: inline-block;
  background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}
.inputProcurar:hover::before {
  border-color: black;
}
.inputProcurar:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

input.obrigatorio,
select.obrigatorio {
  border: solid 2px #b76ba3 !important;
}

@media (max-width: 600px){  
  label.compacto,
  input.compacto,
  textarea.compacto,
  select.compacto {
    display: inline !important;
  }
}

