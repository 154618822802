@media screen and (width: 1152px) and (height: 864px) {
  .table {
    overflow-x: auto;
    display: block;
    width: 100%;
  }
  .table th {
    white-space: nowrap !important;  
  }
}

@media (max-width:1199px) {
  .table {
    overflow-x: auto;
    display: block;
    width: 100%;
  }
  .table th {
    white-space: nowrap !important;  
  }
}

.table {
  white-space: nowrap;
  table-layout: fixed;
}

.table th {
  white-space: normal !important;  
}

.table.tabela-formulario {
  white-space: nowrap;
  table-layout: unset;
}

.tabela-fixa tbody {
  overflow-y: scroll;
  width: 100%;
}

.tabela-fixa thead {
  padding-right: 17px !important;
}

.tabela-fixa thead,
.tabela-fixa tbody,
.tabela-fixa tr,
.tabela-fixa td,
.tabela-fixa th {
  display: block;
}

.tabela-fixa tbody td,
.tabela-fixa tbody th,
.tabela-fixa thead > tr > th {
  float: left;
  position: relative;
}

.tabela-fixa tbody td::after,
.tabela-fixa tbody th::after,
.tabela-fixa thead > tr > th::after {
  content: '';
  clear: both;
  display: block;
}

.responsiva {
  overflow-y: hidden;
  overflow-x: auto;
}

table tr,
td,
th {
  padding: 2px !important;
  margin: 0 !important;
}

tr {
  height: 30px;
}

td,
th {
  vertical-align: middle !important;
  height: inherit;
}

table.table .l-5 {
  width: 5%;
}

table.table .l-10 {
  width: 10%;
}

table.table .l-15 {
  width: 15%;
}

table.table .l-20 {
  width: 20%;
}

table.table .l-25 {
  width: 25%;
}

table.table .l-30 {
  width: 30%;
}

table.table .l-35 {
  width: 35%;
}

table.table .l-40 {
  width: 40%;
}

table.table .l-45 {
  width: 45%;
}

table.table .l-50 {
  width: 50%;
}

table.table .l-55 {
  width: 55%;
}

table.table .l-60 {
  width: 60%;
}

table.table .l-65 {
  width: 65%;
}

table.table .l-70 {
  width: 70%;
}

table.table .l-75 {
  width: 75%;
}

table.table .l-80 {
  width: 80%;
}

table.table .l-85 {
  width: 85%;
}

table.table .l-90 {
  width: 90%;
}

table.table .l-95 {
  width: 95%;
}

table.table .l-100 {
  width: 100%;
}

table.table .lm-10 {
  min-width: 10px;
}

table.table .lm-30 {
  min-width: 30px;
}

table.table .lm-50 {
  min-width: 50px;
}

table.table .lm-75 {
  min-width: 75px;
}

table.table .lm-100 {
  min-width: 100px;
}

table.table .lm-150 {
  min-width: 150px;
}

table.table .lm-200 {
  min-width: 200px;
}

table.table .lm-300 {
  min-width: 300px;
}

table.table .lm-400 {
  min-width: 400px;
}

table.table .badge-danger {
  text-transform: none;
}
