.btn[disabled] {
  opacity: 0.2 !important;
  filter: alpha(opacity=20) !important; /* IE8 E ANTERIOR */
}

.btn-regular {
  margin-top: -3px !important;
}

.btn-compacto {
  height: 22px !important;
  padding-top: 0px !important;
}

.btn-compacto i {
  font-size: 0.9em;
}

 .pulsar {
	box-shadow: 0 0 0 0 #00a28a; 
	transform: scale(1);
	animation: pulsar 2s infinite;
	border: 0px !important;
}

.margem-salvar{
	margin-left: 15px;
	margin-right: 30px !important;
}

@keyframes pulsar {
	0% {transform: scale(0.95);	box-shadow: 0 0 0 0 #00a28a;}
	70% {transform: scale(1.25); box-shadow: 0 0 0 10px #00a28a;}
	100% {transform: scale(0.95);box-shadow: 0 0 0 0 #00a28a;}
}