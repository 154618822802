.btn-floated {
  bottom: 2.75rem !important;
}

.input-group:not(.input-group-alt) .form-control {
  top: 0 !important;
}

body {
  font-family: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica Neue', 'Arial', 'sans-serif' !important;
}

.page-inner {
  padding: 0.3rem 0 !important;
}

.pesquisa-sem-borda {
  border: none !important;
}
